<template>
  <div class="test_page">
    <div class="top">
      <div class="top_title">PDA全国足球特色幼儿园</div>
      <div class="top_desc">测试体系</div>
    </div>
    <div class="content">
      <div class="desc">
        近几年，国家在《纲要》《3-6岁儿童学习与发展指南》以及幼儿园管理流程当中明确提出了要对幼儿进行体质检测，建立健全幼儿体质管理系统。为了帮助幼儿园做好体质及运动能力测评，更好地开展园内体育活动，有效提升在园孩子的体质水平，全国足球特色幼儿园体系平台推出科学系统的PDA测评体系（Physical
        Data Archives）。
        <br /><br />PDA测评体系根据《3-6岁儿童健康发展指南》要求，坚持“遵循幼儿的发展规律和学习特点”，“关注幼儿身心全面和谐发展”和“尊重幼儿发展的个体差异”的三大原则。
        <br /><br />
        PDA测评体系由“体质测试”和“运动测试”两部分组成，构建全面的幼儿运动健康动态数据体系。以“数据比对”、“学员报告”等形式多维度评估全国注册幼儿运动数据，决策指导幼儿体育教育工作的科学开展。
        <br />
        PDA测评体系所设计项目以“游戏化、精准计测、量化比对、专业转化”为原则，按照幼儿发展规律和特点，促进幼儿体质健康协调发展。
        <br />
        8大体质测试项目：身高、体重、臂展、胸围、肺活量、视力、坐位体前屈、体脂率；<br />
        8大运动测试主题：跑动能力、跳跃能力、敏捷性、平衡性、协调性、核心力量、物体控制-手、物体控制-脚。<br />
        每个运动测试主题设置3个难度级别，以适应不同年龄阶段幼儿发展的个体差异。<br /><br />
        PDA测评体系通过对各个年龄段健康及运动数据的采集和研究分析，不断迭代幼儿足球活动课程及活动设计，动态监测评估幼儿运动能力成长轨迹；同时，让所在幼儿园老师与孩子家长更清晰地了解孩子的健康趋向、运动能力，以更好的给予孩子运动指导、让孩子健康成长。
        <br /><br />
        <div style="width: 100%; text-align: right">
          PDA测评体系专家组<br />2020年5月15日
        </div>
      </div>
      <div class="pdf tab">
        <img
          src="../../assets/img/test/pdf.png"
          style="width: 50px; margin-bottom: 10px"
          alt="PDA测试项目介绍"
        />
        <div>
         <a target="_blank" href="/PDA测试项目介绍.pdf">PDA测试项目介绍</a>
        </div>
      </div>
    </div>
    <div class="btn tab" @click="goTest" v-if="this.$store.state.userInfo.teacherId">开始测试</div>
  </div>
</template>

<script>
export default {
  name: "test",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    goTest() {
      if (this.$store.state.userInfo.token) {
        this.api.park
          .invoiceType({ schoolId: this.$store.state.userInfo.schoolId })
          .then((res) => {
            // this.phone = res.data.mobile.slice(1);
            if (res.code) {
              if (res.data !== 5 && this.$route.query.welfareTest) {
                this.$notice({
                  message: "仅公益园用户使用",
                }).isShow();
                return 
              } else {
                this.$router.push({ path: "/admin/teacherHome" });
              }
            }
          });
      } else {
        this.$store.commit("setLoginState", "login");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.test_page {
  width: 80%;
  margin: 100px auto 0;
  .top {
    width: 100%;
    height: 400px;
    @include bg("../../assets/img/test/pda1.png");
    color: #1c3062;
    padding-bottom: 80px;
    position: relative;
    @include flex(column, center, center);
    .top_title {
      font-size: 40px;
      line-height: 40px;
      display: block;
      margin-bottom: 40px;
    }
    .top_desc {
      font-size: 50px;
      line-height: 50px;
      font-weight: bold;
      position: relative;
    }
    .top_desc::before,
    .top_desc::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -3px;
      width: 40px;
      height: 6px;
      background-color: #1c3062;
    }
    .top_desc::before {
      left: -100px;
    }
    .top_desc::after {
      right: -100px;
    }
  }
  .content {
    padding: 60px 80px;
    line-height: 40px;
    font-size: 24px;
    background: #f7f7f7;
    text-align: left;
    .pdf {
      color: #cb1b31;
      text-decoration: underline;
      margin-top: 40px;
      display: inline-block;
    }
  }
  .btn {
    margin: 68px auto;
    width: 300px;
    height: 65px;
    line-height: 65px;
    text-align: center;
    font-size: 30px;
    color: #fff;
    background: #cb1b31;
    border-radius: 50px;
  }
}
</style>
